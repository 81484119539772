<template>
  <div class="order">
    <!-- 提示 -->
    <div class="hint">
      <img class="hint_img" src="@/assets/img/Home/warning.png" object-fit="contain" />
      <span class="hint_zhu">注：</span>
      <span class="hint_tip">知识套餐可以选择全套购买或灵活组合购买</span>
    </div>
    <!-- 确认订单 -->
    <div class="affirm">
      <div class="affirm_title">确认订单</div>
      <div class="affirm_centent">
        <div class="affirm_left">
          <div class="affirm_img">
            <el-image :src="detail.img"></el-image>
          </div>
          <div class="affirm_name">
            <div class="name">{{ detail.tiitle }}</div>
            <div class="tip">
              <span class="way" v-for="(item, index) in detail.curForms" :key="index">{{ index != 0 ? " · " : "" }}{{
                item
              }}</span>
              <span class="popularity">
                <!-- <img class="popularity_img" src="@/assets/img/Home/Frame.png" object-fit="contain" /> -->
                <span class="pop">人气值{{ detail.clicks }}</span>
              </span>
            </div>
            <div v-if="detail.productLine == 3" class="ser">
              <img src="@/assets/img/Home/content-bkg.png" object-fit="contain" />
            </div>
          </div>
        </div>
        <div class="affirm_price">
          ￥{{
            directionPrice && choose.type == 1 ? directionPrice : detail.price
          }}
        </div>
      </div>
    </div>
    <!-- 购买方式 -->
    <div class="buy_way">
      <div class="buy_title">购买方式</div>
      <div class="way">
        <div class="way_left">
          <div class="left_tip">组合套餐可组合或单独选择课购买</div>
          <div class="way_type" :class="{ way_type_choose: choose.type == 1 }">
            <div :class="choose.type == 1 ? 'active' : 'type-item'" @click="choose.type = 1">
              <div>全科套餐</div>
              <img v-if="choose.type == 1" class="type-img" src="@/assets/img/study/chooseblue.png" mode="aspectFit" />
            </div>
            <div v-if="domain.isShop == 1" :class="choose.type == 2 ? 'active' : 'type-item'" @click="choose.type = 2">
              <div>灵活购买</div>
              <img v-if="choose.type == 2" class="type-img" src="@/assets/img/study/chooseblue.png" mode="aspectFit" />
            </div>
          </div>
          <el-divider class="divider" v-if="choose.type == 2" />
          <div class="choose_list" v-if="choose.type == 2">
            <el-collapse class="docList">
              <el-collapse-item v-for="(item, index) in kcList" v-show="item.kcLIst.length > 0" :key="index"
                @click="chooseKb(item)">
                <template slot="title">
                  <div class="docList-title">
                    <div style="display: flex;align-items: center;" class="ellipsis">
                      <div style="max-width: 300px;" class="elp">{{ item.kcName }}</div>
                      <div
                        style="margin-left: 5px;width: 19px;height: 19px;background: #2586F5;opacity: 1;font-size: 12px;font-family: MicrosoftYaHei, MicrosoftYaHei;font-weight: normal;text-align: center;border-radius: 50%;color: #FFFFFF;line-height: 19px;">
                        {{ item.kcLIst ? item.kcLIst.length : 0 }}</div>

                    </div>
                    <!-- <div class="choose_num" v-if="choose.kcdetail.length > 0">
                      {{ choose.kcdetail.length }}
                    </div> -->
                  </div>
                </template>
                <div v-for="(kcitem, kcindex) in item.kcLIst" :key="kcindex" :class="choose.kcides.includes(kcitem.id)
                  ? 'docList-item active elp'
                  : 'docList-item elp'
                  " @click="chooseClick(kcitem, item)">
                  {{ kcitem.yearName }}【{{ liveEnum[kcitem.type] }}】
                  {{ kcitem.title }}
                </div>
              </el-collapse-item>
            </el-collapse>
          </div>
        </div>
        <div class="way_right">
          <div class="right_top">
            <div class="title">
              {{ choose.type == 2 ? "已选课程" : "全科套餐包含课程" }}
            </div>
            <div class="pipe_img" @click="adminClick" v-if="choose.type == 2 && choose.kcdetail.length > 0">
              <el-image v-if="!delDio" :src="require('@/assets/img/partner/administration.png')"></el-image>
              <el-image v-if="delDio" :src="require('@/assets/img/partner/mulu.png')"></el-image>
              <span :style="delDio ? 'color: #2586F5' : ''" @click="delDio ? delDio = false : delDio = true"
                class="tip">管理</span>
            </div>
          </div>
          <div class="right_bottom">
            <div class="bottom_item" v-if="choose.type === 1 && yearsList.length > 0">
              <div v-for="(item, index) in yearsList" :key="index" class="class-text">
                {{ item.kcName }}
              </div>
            </div>
            <div v-if="choose.type === 2">
              <el-empty v-if="choose.kcdetail.length === 0" description="暂无课程内容" />
              <el-table v-if="choose.kcdetail?.length" :data="choose.kcdetail" :row-class-name="tableRowClassName">
                <el-table-column prop="className" label="课程名称" width="500">
                  <template slot-scope="scope">
                    <div class="docList-item elp">
                      {{ scope.row.yearName }}【{{ liveEnum[scope.row.type] }}】
                      {{ scope.row.title }}
                    </div>
                  </template>
                </el-table-column>
                <el-table-column prop="classPrice" label="课程价格" width="125">
                  <template slot-scope="scope">
                    <div class="choose-class-price">
                      ￥{{ scope.row.entrantsPrice }}
                    </div>
                  </template></el-table-column>
                <el-table-column fixed="right">
                  <template slot-scope="scope">
                    <el-button v-if="delDio" type="text" size="small" @click.native.prevent="deleteRow(scope.row)">
                      <i class="el-icon-close close" />
                      <span class="del">删除</span>
                    </el-button>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 支付信息 -->
    <div class="pay">
      <div class="pay_title">支付信息</div>
      <div class="pay_tip">
        <div class="indent">
          订单金额： ￥{{
            directionPrice && choose.type == 1 ? directionPrice : allPrice
          }}
        </div>
        <div class="discounts">优惠金额：￥0.00</div>
        <div class="dealwith">
          应付金额： ￥{{
            directionPrice && choose.type == 1 ? directionPrice : allPrice
          }}
        </div>
        <div class="select">
          已选套餐:{{ choose.type == 1 ? "全科套餐" : "组合套餐" }}
        </div>
      </div>
    </div>
    <!-- 支付方式 -->
    <div class="pay_way">
      <div class="way_title">支付方式</div>
      <div class="way_tip">
        <div :class="choose.payment === 2 ? 'activepay' : 'pay-btn'" @click="choose.payment = 2">
          <img class="logo-img" src="@/assets/img/Home/alipay.png" />
          支付宝支付
        </div>
        <div :class="choose.payment === 1 ? 'activepay' : 'pay-btn'" @click="choose.payment = 1">
          <img class="logo-img" src="@/assets/img/Home/weixinpay.png" />
          微信支付
        </div>
      </div>
    </div>
    <!-- 提交订单 -->
    <div class="submit">
      <div class="submit_price">
        需付金额：<span class="price">{{
          directionPrice && choose.type == 1
            ? directionPrice.toFixed(2)
            : allPrice.toFixed(2)
        }}</span>
      </div>
      <div class="price_btn" @click="zhifu">立即购买</div>
    </div>
    <wx-payment v-if="ifwXpay" :url="payDetail.code_url" :out-trade-no="payDetail.out_trade_no"
      :price="payDetail.total_fee" @invalid="wxPay()" />
  </div>
</template>
<script>
import Bus from "@/api/bus";
import Vue from "vue";
import { getClass } from "@/api/home";
import wxPayment from "./wxPayment.vue";
import { getInfo, getDomain } from "@/api/cookies";
import { OrderClient } from "@/api/orderClient";
const orderClient = new OrderClient();
import { Know } from "@/api/know";
import { liveEnum } from "@/api/emun";
const know = new Know();
export default {
  components: { wxPayment },
  data() {
    return {
      liveEnum: liveEnum,
      delDio: false,
      classId: null,
      domain: getDomain(),
      detail: {}, // 班型详情
      choose: {
        type: 1,
        payment: 1,
        kcBoxId: 0,
        kcdetail: [],
        kcides: [],
      },
      yearsList: [],
      kcBoxList: [],
      kcList: [],
      allPrice: 0,
      directionPrice: 0,
      directionBox: {},
      payDetail: {},
      ifwXpay: false,
      loading: null,
    };
  },
  beforeDestroy() {
    this.loading.close();
  },

  async created() {
    if (this.$route.query.direction) {
      this.choose.kcBoxId = await this.$route.query.direction;
    }
    this.userInfo = await getInfo();
    this.$nextTick(() => {
      if (!this.userInfo) {
        Vue.prototype.goLoginView(false);
        return;
      }
    });
    this.classId = this.$route.query.classId;
    await this.getDetail();
    await this.getkcBox();
    this.getyears();
  },
  // computed: {
  //   itemAll() {},
  // },
  methods: {
    // 管理
    adminClick() { },
    // 课程推荐详情
    async getDetail() {
      await getClass(this.classId).then((res) => {
        console.log(res);
        if (res.data.BX.curForms) {
          const item = res.data.BX;
          res.data.BX.curForms = res.data.BX.curForms.split(",");
          const data = [];
          if (item.isData == 0) {
            data.push("专属资料");
          }
          if (item.isQuestion == 0) {
            data.push("专属题库");
          }
          if (item.isExamination == 0) {
            data.push("专属考场");
          }
          /*   if (item.jcServer == 0) {
            data.push("专属教材");
          }
          if (item.jfServer == 0) {
            data.push("专属教服");
          } */
          if (res.data.BX.jcServer) {
            data.push("专属教材");
          }
          if (res.data.BX.jfServer) {
            data.push("专属教服");
          }
          item.coufig = data;
        }
        this.detail = res.data.BX;
        this.directionPrice = this.detail.price;
        console.log(this.detail);
        this.$forceUpdate();
      });
    },
    // 获取知识套餐课程
    getkcBox() {
      know.getCoursePackageList(this.classId, 9999, 1).then((res) => {
        res.rows.forEach((element) => {
          console.log(element, this.$route.query.direction);
          if (element.id == this.$route.query.direction) {
            this.directionPrice = element.price;
            this.directionBox = element;
          }
        });
        this.kcBoxList = res.rows;
      });
    },
    tableRowClassName() {
      return "no-line";
    },
    // 删除已选课程
    deleteRow(row) {
      this.choose.kcdetail = this.choose.kcdetail.filter((item, index) => {
        return item.id != row.id;
      });
      this.choose.kcides = this.choose.kcdetail.map((item, index) => {
        return item.id;
      });
      const price = this.choose.kcdetail.map((item, index) => {
        return item.entrantsPrice;
      });
      var sum = 0;
      for (const i of price) {
        sum += i;
      }
      this.allPrice = sum;
      this.$message("删除课程成功");
    },
    /* 选择课程方向 */
    chooseKb(item) {
      console.log(item);
      this.choose.kcBoxId = item.id;
      console.log(this.choose.kcBoxId);
      this.choose.name = item.name;
      this.directionPrice = item.price;
      this.$forceUpdate();
    },
    /* 组合购买内容 */
    async getkc(kid) {
      let data = [];
      await know
        .getClassesCourseBuyList(this.classId, undefined, kid, this.userInfo.id)
        .then((res) => {
          if (res.code == 0) {
            for (const item of res.data.audio) {
              item.type = 3;
              data.push(item);
            }
            for (const item of res.data.face) {
              item.type = 5;
              data.push(item);
            }
            for (const item of res.data.imageText) {
              item.type = 4;
              data.push(item);
            }
            for (const item of res.data.live) {
              item.type = 1;
              data.push(item);
            }
            for (const item of res.data.video) {
              item.type = 2;
              data.push(item);
            }
          }
          // console.log(data, "data");
          data = data.filter((item) => item.entrantsPass == 1);
          // console.log(data, "data1");
        });
      return data;
    },
    /* 获取知识套餐包含课程名 */
    async getyears() {
      this.loading = this.$loading({
        lock: true,
        text: "加载中",
        spinner: "",
        background: "rgba(0, 0, 0, 0.1)",
      });
      await know.classesTable(this.classId, this.userInfo.id).then((res) => {
        if (res.code == 0) {
          this.yearsList = res.data.array;
          this.kcList = res.data.array;
          for (const item of this.kcList) {
            this.getkc(item.kId).then((res) => {
              item.kcLIst = res;
            });
          }
          console.log(this.kcList, 'this.kcList');
          this.$forceUpdate();
        }
      });
      this.loading.close();
    },
    /* 选中组合购买 */
    chooseClick(kcitem, father) {
      if (kcitem.entrantsPass == 1) {
        if (this.choose.kcides.includes(kcitem.id)) {
          this.choose.kcdetail = this.choose.kcdetail.filter((item, index) => {
            return item.id != kcitem.id;
          });
        } else {
          this.choose.kcdetail.push(kcitem);
        }
        this.choose.kcides = this.choose.kcdetail.map((item, index) => {
          return item.id;
        });
        const price = this.choose.kcdetail.map((item, index) => {
          return item.entrantsPrice;
        });
        var sum = 0;
        for (const i of price) {
          sum += i;
        }
        console.log(kcitem, this.choose);
        this.allPrice = sum;
        // father.choose = father.kcLIst.filter((item, index) => {
        //   return this.choose.kcides.includes(item.id)
        // })
      } else {
        this.$message.warning("此商品为异常商品，不可购买");
      }

      this.$forceUpdate();
    },
    zhifu() {
      if (this.userInfo) {
        if (this.choose.type === 1) {
          // console.log("断点1", this.kcBoxList.length > 0, !this.choose.kcBoxId);
          // if (this.kcBoxList.length > 0 && this.choose.kcBoxId) {
          //   console.log(
          //     "断点",
          //     this.kcBoxList.length > 0 && !this.choose.kcBoxId
          //   );
          //   return;
          // }
          orderClient
            .addOrdersPartner(
              this.choose.kcBoxId ? this.choose.kcBoxId : this.classId,
              localStorage.getItem("userId"),
              this.choose.kcBoxId ? this.choose.name : this.detail.tiitle,
              this.choose.kcBoxId ? 16 : 1,
              this.choose.kcBoxId ? this.choose.name : this.detail.tiitle,
              this.choose.kcBoxId ? this.choose.kind : this.detail.kind,
              this.choose.payment
            )
            .then((res) => {
              if (res.code == 0) {
                console.log(res.msg);
                if (this.choose.payment === 1) {
                  this.payDetail = res.data;
                  this.ifwXpay = true;
                  this.$forceUpdate();
                } else {
                  this.$router.push({
                    path: "/payment",
                    query: { data: res.data.msg },
                  });
                }
              } else {
                this.$message.error(res.msg);
              }
            });
        } else {
          const of = new Date().getTime();
          /*  */
          localStorage.setItem(`saveId${of}`, JSON.stringify(this.kcList));
          this.$router.push(
            `/orders/classSure?ids=${this.choose.kcides}&class=${this.classId}&saveId=${of}` /* { name: "组合购买", params: { ids: this.choose.kcides, class: this.classId } } */
          );
        }
      } else {
        Vue.prototype.goLoginView(true);
      }
    },
  },
};
</script>
<style lang="less" scoped>
.order {
  width: 100%;

  .hint {
    width: 1200px;
    height: 42px;
    background: #fff1ef;
    border-radius: 6px 6px 6px 6px;
    opacity: 1;
    margin: 11px auto 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 15px;

    .hint_img {
      margin-right: 6px;
    }

    .hint_zhu {
      font-size: 14px;
      font-weight: 600;
      font-family: MicrosoftYaHei-, MicrosoftYaHei;
      color: #444;
    }

    .hint_tip {
      font-size: 14px;
      font-family: MicrosoftYaHei-, MicrosoftYaHei;
      font-weight: normal;
      color: #555555;
    }
  }

  .affirm {
    width: 1200px;
    margin: 20px auto 0;

    .affirm_title {
      margin-left: 5px;
      font-size: 20px;
      font-family: MicrosoftYaHei-Bold-, MicrosoftYaHei-Bold;
      font-weight: 600;
      color: #333333;
    }

    .affirm_centent {
      width: 1200px;
      height: 146px;
      background: #ffffff;
      border-radius: 7px 7px 7px 7px;
      opacity: 1;
      margin-top: 10px;
      padding: 16px 27px 16px 14px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .affirm_left {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;

        .affirm_img {
          width: 203px;
          height: 114px;
          border-radius: 6px 6px 6px 6px;
          opacity: 1;
          margin-right: 14px;

          .el-image {
            width: 100%;
            height: 100%;
            border-radius: 6px;
          }
        }

        .affirm_name {
          .name {
            font-size: 18px;
            font-family: MicrosoftYaHei-, MicrosoftYaHei;
            font-weight: normal;
            color: #333333;
          }

          .tip {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            margin-top: 14px;

            .way {
              margin-right: 4px;
              font-size: 14px;
              font-family: MicrosoftYaHei-, MicrosoftYaHei;
              font-weight: normal;
              color: #2586f5;
            }

            .popularity {
              margin-left: 23px;
              display: flex;
              justify-content: flex-start;
              align-items: center;

              .popularity_img {
                width: 16px;
                height: 16px;
              }

              .pop {
                font-size: 12px;
                font-family: PingFang SC-Regular, PingFang SC;
                font-weight: 400;
                color: #777a82;
                margin-left: 2px;
              }
            }
          }

          .ser {
            margin-top: 19px;
          }
        }
      }

      .affirm_price {
        font-size: 24px;
        font-family: MicrosoftYaHei-Bold-, MicrosoftYaHei-Bold;
        font-weight: 600;
        color: #333333;
      }
    }
  }

  .buy_way {
    width: 1200px;
    margin: 20px auto 0;

    .buy_title {
      margin-left: 5px;
      font-size: 20px;
      font-family: MicrosoftYaHei-Bold-, MicrosoftYaHei-Bold;
      font-weight: 600;
      color: #333333;
    }

    .way {
      width: 1200px;
      background: #ffffff;
      border-radius: 7px 7px 7px 7px;
      opacity: 1;
      margin-top: 10px;
      display: flex;

      .way_left {
        width: 450px;
        border-right: 2px solid #e7e7e7;

        .left_tip {
          font-size: 12px;
          font-family: MicrosoftYaHei-, MicrosoftYaHei;
          font-weight: normal;
          color: #888888;
          margin-bottom: 26px;
          padding: 24px 0 0 25px;
        }

        .way_type {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          padding-left: 25px;

          .type-item {
            width: 160px;
            height: 48px;
            border-radius: 8px 8px 8px 8px;
            opacity: 1;
            border: 1px solid #e7e7e7;
            font-size: 18px;
            font-family: MicrosoftYaHei-, MicrosoftYaHei;
            font-weight: normal;
            color: #666666;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 58px;
          }

          .active {
            width: 160px;
            height: 48px;
            border-radius: 8px 8px 8px 8px;
            opacity: 1;
            border: 1px solid #2586f5;
            font-size: 18px;
            font-family: MicrosoftYaHei-, MicrosoftYaHei;
            font-weight: normal;
            color: #2586f5;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 58px;
            position: relative;

            .type-img {
              position: absolute;
              bottom: 0;
              right: 0;
            }
          }
        }

        .way_type_choose {
          padding-bottom: 50px;
        }

        .divider {
          margin: 35px 0 21px;
        }

        .choose_list {
          padding: 0 28px 3px 26px;

          .docList {
            border: none;

            /deep/ .el-collapse-item__header {
              width: 396px;
              height: 40px;
              background: #f5f7f9;
              border-radius: 4px 4px 4px 4px;
              opacity: 1;
              margin-bottom: 16px;
              border: none;
              padding: 10px;

              .docList-title {
                display: flex;
                justify-content: flex-start;
                align-items: center;

                .choose_num {
                  width: 19px;
                  height: 19px;
                  background: #2586f5;
                  opacity: 1;
                  font-size: 12px;
                  font-family: MicrosoftYaHei-, MicrosoftYaHei;
                  font-weight: normal;
                  color: #ffffff;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  border-radius: 50%;
                  margin-left: 10px;
                }
              }
            }

            /deep/.el-collapse-item__wrap {
              border: none;

              .el-collapse-item__content {
                padding-bottom: 5px;

                .docList-item {
                  margin-left: 5px;
                  width: 365px;
                  height: 32px;
                  border-radius: 4px 4px 4px 4px;
                  opacity: 1;
                  border: 1px solid #e7e7e7;
                  line-height: 32px;
                  margin-bottom: 14px;
                  font-size: 13px;
                  font-family: MicrosoftYaHei-, MicrosoftYaHei;
                  font-weight: normal;
                  color: #555555;

                  text-align: left;
                  padding: 0 10px;
                }

                .active {
                  // margin-left: 5px;
                  // width: 335px;
                  // height: 32px;
                  background: rgba(37, 134, 245, 0.1);
                  opacity: 1;
                  border: 1px solid #2586f5;
                  // display: flex;
                  // justify-content: center;
                  // align-items: center;
                  // margin-bottom: 14px;
                  color: #2586f5;
                  font-size: 13px;
                  font-family: MicrosoftYaHei-, MicrosoftYaHei;
                  font-weight: normal;
                }
              }
            }
          }
        }
      }

      .way_right {
        padding: 14px 20px 0;
        width: 748px;

        .right_top {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .title {
            font-size: 16px;
            font-family: MicrosoftYaHei-Bold-, MicrosoftYaHei-Bold;
            font-weight: 600;
            color: #333333;
          }

          .pipe_img {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            cursor: pointer;

            .el-image {
              width: 20px;
              height: 20px;
              margin-right: 4px;
            }

            .tip {
              font-size: 15px;
              font-family: MicrosoftYaHei-, MicrosoftYaHei;
              font-weight: normal;
              color: #555555;
            }
          }
        }

        .right_bottom {
          .bottom_item {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            padding: 12px;
            flex-wrap: wrap;

            .class-text {
              font-size: 16px;
              font-family: MicrosoftYaHei-, MicrosoftYaHei;
              font-weight: normal;
              color: #444444;
              padding: 10px 12px;
              background: #f6f6f6;
              border-radius: 4px 4px 4px 4px;
              opacity: 1;
              margin-right: 20px;
              margin-bottom: 10px;
            }
          }

          .docList-item {
            border-radius: 4px 4px 4px 4px;
            opacity: 1;
            border: 1px solid #e7e7e7;
            font-size: 15px;
            font-family: MicrosoftYaHei-, MicrosoftYaHei;
            font-weight: normal;
            color: #333333;
            padding: 10px 26px;
            width: 450px;
            text-overflow: ellipsis;
            overflow: hidden;
            word-wrap: normal;
          }

          .choose-class-price {
            font-size: 18px;
            font-family: MicrosoftYaHei-Bold-, MicrosoftYaHei-Bold;
            font-weight: bold;
            color: #f9431e;
          }

          .close {
            font-size: 16px;
            margin-right: 7px;
          }

          .del {
            font-size: 14px;
            font-family: MicrosoftYaHei-, MicrosoftYaHei;
            font-weight: normal;
            color: #2586f5;
            margin-top: -1px;
          }
        }
      }
    }
  }

  .pay {
    width: 1200px;
    margin: 20px auto 0;

    .pay_title {
      margin-left: 5px;
      font-size: 20px;
      font-family: MicrosoftYaHei-Bold-, MicrosoftYaHei-Bold;
      font-weight: 600;
      color: #333333;
    }

    .pay_tip {
      margin-top: 10px;
      width: 1200px;
      height: 55px;
      background: #ffffff;
      border-radius: 7px 7px 7px 7px;
      opacity: 1;
      padding: 18px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      font-size: 14px;
      font-family: MicrosoftYaHei-, MicrosoftYaHei;
      font-weight: normal;
      color: #333333;

      .indent,
      .discounts,
      .dealwith {
        margin-right: 190px;
      }
    }
  }

  .pay_way {
    width: 1200px;
    margin: 20px auto 0;

    .way_title {
      margin-left: 5px;
      font-size: 20px;
      font-family: MicrosoftYaHei-Bold-, MicrosoftYaHei-Bold;
      font-weight: 600;
      color: #333333;
    }

    .way_tip {
      margin-top: 10px;
      width: 1200px;
      height: 96px;
      background: #ffffff;
      border-radius: 7px 7px 7px 7px;
      opacity: 1;
      opacity: 1;
      padding: 20px;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .pay-btn {
        width: 176px;
        height: 56px;
        border-radius: 4px 4px 4px 4px;
        opacity: 1;
        border: 1px solid #dbdbdb;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 18px;
        font-family: MicrosoftYaHei-, MicrosoftYaHei;
        font-weight: normal;
        color: #333333;
        margin-right: 27px;
      }

      .activepay {
        width: 176px;
        height: 56px;
        background: rgba(37, 134, 245, 0.1);
        border-radius: 4px 4px 4px 4px;
        opacity: 1;
        border: 1px solid #2586f5;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 18px;
        font-family: MicrosoftYaHei-, MicrosoftYaHei;
        font-weight: normal;
        color: #333333;
        margin-right: 27px;
      }

      .logo-img {
        width: 22px;
        height: 22px;
        margin-right: 10px;
      }
    }
  }

  .submit {
    width: 1200px;
    height: 90px;
    background: #ffffff;
    border-radius: 7px 7px 7px 7px;
    opacity: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: 20px auto 0;
    padding-right: 20px;

    .submit_price {
      font-size: 16px;
      font-family: MicrosoftYaHei-, MicrosoftYaHei;
      font-weight: normal;
      color: #777777;
      margin-right: 20px;

      .price {
        font-size: 30px;
        font-family: MicrosoftYaHei-Bold-, MicrosoftYaHei-Bold;
        font-weight: 600;
        color: #f9431e;
        margin-left: 12px;
      }
    }

    .price_btn {
      width: 220px;
      height: 48px;
      background: linear-gradient(88deg, #ff6d1e 0%, #fe420e 100%);
      border-radius: 24px 24px 24px 24px;
      opacity: 1;
      font-size: 16px;
      font-family: Source Han Sans CN-Regular, Source Han Sans CN;
      font-weight: 400;
      color: #ffffff;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
  }
}
</style>
